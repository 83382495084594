import "./Arrows.scss";

import arrowBottomLeft from "../assets/arrow-bottom-left.svg";
import arrowBottomrRight from "../assets/arrow-bottom-right.svg";
import arrowMiddleLeft from "../assets/arrow-middle-left.svg";
import arrowMiddlRight from "../assets/arrow-middle-right.svg";
import arrowTopLeft from "../assets/arrow-top-left.svg";
import arrowTopRight from "../assets/arrow-top-right.svg";

export const Arrows = () => (
  <>
    <img src={arrowBottomLeft} alt="Arrow" className="Arrow bottom-left" />
    <img src={arrowBottomrRight} alt="Arrow" className="Arrow bottom-right" />
    <img src={arrowMiddleLeft} alt="Arrow" className="Arrow middle-left" />
    <img src={arrowMiddlRight} alt="Arrow" className="Arrow middle-right" />
    <img src={arrowTopLeft} alt="Arrow" className="Arrow top-left" />
    <img src={arrowTopRight} alt="Arrow" className="Arrow top-right" />
  </>
);
