import { Header } from "./components/Header";
import { About } from "./components/About";
import { JoinForm } from "./components/JoinForm";
import { Team } from "./components/Team";
import { Faq } from "./components/Faq";
import { Blog } from "./components/Blog";
import { Footer } from "./components/Footer";
import { Technology } from "./components/Technology";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <JoinForm />
      <Technology />
      <Blog />
      <Team />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
