import "./Footer.scss";

import { ReactComponent as FbIcon } from "../assets/face.svg";
import { ReactComponent as InstaIcon } from "../assets/insta.svg";
import frsi from "../assets/frsi.png";
import sektor from "../assets/sektor.png";

export const Footer = () => (
  <footer className="footer-container">
    <div className="Footer">
      <div className="Footer__menu">
        <a id="home" className="menu-item" href="#how-it-works">
          Jak to działa?
        </a>
        <a id="about" className="menu-item" href="#benefits">
          Twoje korzyści
        </a>
        <a id="contact" className="menu-item" href="#about-us">
          O nas
        </a>
        <a id="contact" className="menu-item" href="#faq">
          FAQ
        </a>
      </div>

      <button
        type="button"
        className="join-button"
        onClick={() => {
          document.getElementById("join-form").scrollIntoView();
        }}
      >
        Dołącz do Cozato
      </button>

      <div className="Footer__social">
        <a
          href="https://www.facebook.com/CozatoPL"
          className="fb"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FbIcon />
        </a>

        <a
          href="https://www.instagram.com/cozato_pl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstaIcon />
        </a>
      </div>
    </div>

    <div className="sektor-info">
      <p className="info-text">
        Zrealizowano z pomocą środków Funduszu Sektor 3.0 w ramach programu
        Polsko-Amerykańskiej Fundacji Wolności pn. „Nowe Technologie Lokalnie
        2021 – Sektor 3.0”, którego realizatorem jest Fundacja Rozwoju
        Społeczeństwa Informacyjnego.
      </p>

      <div className="logos">
        <img src={frsi} alt="Logo FRSI" className="sektor-logos" />
        <img src={sektor} alt="Logo Sektor 3.0" className="sektor-logos" />
      </div>
    </div>
  </footer>
);
