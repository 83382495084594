import "./About.scss";

import { ReactComponent as ChatIcon } from "../assets/chat.svg";
import { ReactComponent as RocketIcon } from "../assets/rocket.svg";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import handsFlour from "../assets/hands-flour.png";
import handsMilk from "../assets/hands-milk.png";

export const About = () => (
  <div className="section grey">
    <section className="About" id="how-it-works">
      <h2 className="main-header">
        Wymieniasz - <span className="header-bold">zyskujesz!</span>
      </h2>
      <div className="About__content">
        <div className="About__item">
          <SearchIcon />
          <p className="small-header">
            <span className="text-bold">Wystaw lub wyszukaj</span> <br />
            rzeczy, którym chcesz dać drugie życie.
          </p>
        </div>

        <div className="About__item">
          <ChatIcon />
          <p className="small-header">
            <span className="text-bold">Łatwo zdecyduj</span> <br />z kim,
            gdzie, kiedy i za co chcesz się wymienić.
          </p>
        </div>

        <div className="About__item">
          <RocketIcon />
          <p className="small-header">
            <span className="text-bold">Ciesz się z innymi</span> <br />
            bo na wymianie zyskują wszyscy!
          </p>
        </div>
      </div>
    </section>

    <section className="About" id="benefits">
      <h2 className="main-header About__second-header">
        <span className="header-bold">Co za to</span> - masz Ty?
      </h2>

      <div className="About__benefits">
        <div className="About__benefits-text">
          <p className="About__benefits-content">
            Dzielenie się to pestka!{" "}
            <span className="text-bold">
              Zyskaj więcej czasu i spokój ducha.
            </span>{" "}
            W końcu wystawienie i szukanie przedmiotów na Cozato jest intuicyjne
            i szybkie.
          </p>

          <p className="About__benefits-content">
            <span className="text-bold">Oszczędzasz z nami! </span> Łatwo
            wyszukuj w pełni sprawne przedmioty dobrej jakości – za darmo lub za
            symboliczną opłatę w formie wymiany.
          </p>

          <p className="About__benefits-content">
            Wystawiasz? <span className="text-bold">Decydujesz! </span>Po
            otrzymaniu propozycji wymiany{" "}
            <span className="text-bold">
              sam_a wybierzesz osobę, której chcesz przekazać przedmiot
            </span>
            , porównując rankingi użytkowników i szczegóły ich ofert.
          </p>
        </div>

        <img
          src={handsFlour}
          alt="Ręce podające rzeczy"
          className="About__image"
        />

        <div className="About__benefits-text text-2">
          <p className="About__benefits-content">
            Tylko sąsiedzka wymiana.{" "}
            <span className="text-bold">
              Bezpiecznie i bez zbędnych nerwów{" "}
            </span>
            znajdziesz w wybranej okolicy osobę, która spełnia Twoje wymiankowe
            oczekiwania.
          </p>

          <p className="About__benefits-content">
            Mówi się, że wszystko, co jest nam potrzebne, zostało już
            wyprodukowane. Wymieniając się przedmiotami na Cozato{" "}
            <span className="text-bold">
              działasz dla planety, ograniczasz konsumpcjonizm i żyjesz less
              waste!
            </span>
          </p>

          <p className="About__benefits-content">
            Chcesz zrobić miejsce w szafie? Garażu? Pawlaczu? Wystawiaj
            przedmioty na Cozato w trzech prostych krokach i{" "}
            <span className="text-bold">
              zdecyduj, ile osób może zgłosić się po Twój przedmiot.
            </span>
          </p>
        </div>

        <img
          src={handsMilk}
          alt="Ręce podające rzeczy"
          className="About__image image-2"
        />
      </div>
    </section>
  </div>
);
