import "./Team.scss";

import kuba from "../assets/kuba.png";
import kasia from "../assets/kasia.png";
import karolina from "../assets/karolina.png";
import wiki from "../assets/wiki.png";
import wera from "../assets/wera.png";
import lukasz from "../assets/lukasz.png";
import maciek from "../assets/maciek.png";
import you from "../assets/you.svg";

import { TeamMember } from "./TeamMember";

const team = [
  {
    imgSrc: kuba,
    name: "Jakub Wasielak",
    role: "koordynator | tech lead",
    className: "kuba"
  },
  {
    imgSrc: kasia,
    name: "Katarzyna Kozłowska",
    role: "front-end dev",
    className: "kasia"
  },
  {
    imgSrc: wiki,
    name: "Wiktoria Stykowska",
    role: "marketing | product manager",
    className: "wiki"
  },
  {
    imgSrc: wera,
    name: "Weronika Kalinowska",
    role: "front-end dev | UI design"
  },
  {
    imgSrc: karolina,
    name: "Karolina Wiatrzyk",
    role: "UX/UI designer"
  },
  {
    imgSrc: lukasz,
    name: "Łukasz Głowacki",
    role: "DevOps"
  },
  {
    imgSrc: maciek,
    name: "Maciej Gil",
    role: "grafik"
  },
  {
    imgSrc: you,
    name: "Może to Ty?",
    role: "Dołącz do zespołu",
    newMember: true
  }
];

export const Team = () => (
  <div className="section yellow more-whitespace" id="about-us">
    <section className="Team">
      <div className="Team__text">
        <h1 className="main-header">My i nasze wymiankowe skarby</h1>

        <p className="subheader">
          Jesteśmy zgraną paczką ludzi z pasją – pasją do działania, do dawania
          od siebie i polepszania świata wokół.
        </p>

        <p className="subheader">
          Wierzymy, że nasza platforma będzie miała realny wpływ na wyhamowanie
          pędzącej nadprodukcji dóbr i zmniejszenie powszechnego
          konsumpcjonizmu. Chcesz do nas dołączyć? Napisz na{" "}
          <a href="mailto:czesc@cozato.pl">czesc@cozato.pl</a>
        </p>
      </div>

      {team.map(member => (
        <TeamMember {...member} key={member.name} />
      ))}
    </section>
  </div>
);
