import "./JoinForm.scss";

import handsLetter from "../assets/hands-letter.png";
import handsPot from "../assets/hands-pot.png";
import { Form } from "./Form";

export const JoinForm = () => (
  <div className="section green more-whitespace" id="join-form">
    <section className="JoinForm">
      <img
        src={handsLetter}
        alt="Ręcę podające list"
        className="hands-image letter"
      />
      <img
        src={handsPot}
        alt="Ręcę podające list"
        className="hands-image pot"
      />

      <h1 className="main-header">
        <span className="header-bold">Dołącz</span> i zacznij się wymieniać!
      </h1>
      <p className="subheader">
        <span className="text-bold">
          Zostań jednym z pierwszych użytkowników platformy,{" "}
        </span>
        dołączając do naszego newslettera.
      </p>

      <p className="subheader">
        Kiedy Cozato ruszy pełną parą – dostaniesz od nas indywidualne
        zaproszenie do dołączenia do nowej społeczności cozatowiczów.
      </p>

      <Form />
    </section>
  </div>
);
