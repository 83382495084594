import "./Faq.scss";

import Collapsible from "react-collapsible";
import bubble from "../assets/bubble.svg";
import rocket from "../assets/rocket-drawing.svg";

const faq = [
  {
    question: "Czy już mogę się wymieniać? Gdzie muszę się zarejestrować?",
    answer:
      "Platforma jeszcze jest w przygotowaniu, ale już niedługo będziemy zapraszać pierwszych użytkowników do jej testowania. Zapisz się do naszego newslettera, a z pewnością nie przegapisz startu Cozato."
  },
  {
    question:
      "Czy aby złożyć oferty wymiany i odbioru trzeba być zarejestrowanym?",
    answer:
      "Każda osoba może przeglądać ogłoszenia na Cozato. Jednak wystawianie własnych przedmiotów oraz składanie ofert wymiany możliwe będzie tylko dla zarejestrowanych użytkowników. Dzięki temu wymianki będą bezpieczniejsze."
  },
  {
    question: "W jakich miastach będzie działać Cozato?",
    answer: (
      <>
        Na początku wystartujemy w trzech miastach, w których wymianki cieszą
        się największą popularnością. Jednak z pewnością mapa Cozato szybko
        poszerzy się o kolejne miejscowości! Jeśli wiesz, że w Twoim regionie
        wymianki są bardzo popularne i chcesz, aby Cozato było tam obecne,
        napisz do nas na <a href="mailto:czesc@cozato.pl">czesc@cozato.pl</a>.
      </>
    )
  },
  {
    question: "Czy w Cozato można będzie płacić za przedmioty pieniędzmi?",
    answer:
      "Nie, Cozato będzie platformą wymiany barterowej. Walutą może być więc wszystko, na co zdecydują się użytkownicy – artykuły spożywcze, chemia, domowe przetwory czy po prostu… uśmiech. :)"
  },
  {
    question: "Czy wymienianie się na Cozato będzie bezpieczne?",
    answer:
      "Dane cozatowiczów nie będą dostępne dla osób postronnych. Wyłącznie, gdy dwoje zarejestrowanych użytkowników zdecyduje się na wspólną wymianę, niezbędne dane zostaną ujawnione stronom transakcji. Dodatkowo system ocen i opinii użytkowników pomoże w rozpoznaniu wszelkich nieprawidłowości."
  }
];

export const Faq = () => (
  <div className="section grey more-whitespace" id="faq">
    <section className="Faq">
      <h2 className="main-header">
        <span className="header-bold">Co za </span>pytania
        <br /> masz jeszcze w głowie?
      </h2>
      {faq.map(item => (
        <Collapsible
          trigger={<p className="CollapseHeader">{item.question}</p>}
          easing="ease-in"
          key={item.question}
        >
          <p>{item.answer}</p>
        </Collapsible>
      ))}

      <img src={bubble} alt="Bubble" className="bubble" />
      <img src={rocket} alt="Bubble" className="rocket" />
    </section>
  </div>
);
