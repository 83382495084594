import "./Technology.scss";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/autoplay/autoplay.scss";

import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Pagination, Autoplay } from "swiper";
import Collapsible from "react-collapsible";

import filtr from "../assets/filtr.png";
import lista from "../assets/lista.png";
import oferta from "../assets/oferta.png";
import opis from "../assets/opis.png";
import { Arrows } from "./Arrows";

export const Technology = () => {
  const [openElement, setOpenElement] = useState(0);
  return (
    <div className="section more-whitespace">
      <section className="Tech" id="tech">
        <h1 className="main-header">Rzuć okiem na Cozato</h1>

        <div className="swiper">
          <Swiper
            slidesPerView={1}
            loop={true}
            modules={[Pagination, Autoplay]}
            pagination
            autoplay
          >
            {TECH.map(item => (
              <SwiperSlide key={item.question}>
                <div className="background">
                  <img
                    src={IMAGES[item.id]}
                    alt="Screenshot z aplikacji Cozato"
                    className="screenshot"
                  />
                </div>
                <div className="swiper-text">
                  <p className="small-header">{item.question}</p>
                  <p>{item.answer}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="Tech__content">
          <div className="Tech__questions">
            {TECH.map(item => (
              <Collapsible
                trigger={<p className="CollapseHeader">{item.question}</p>}
                easing="ease-in"
                key={item.id}
                id={item.id}
                open={openElement === item.id}
                onOpening={() => setOpenElement(item.id)}
              >
                <p>{item.answer}</p>
              </Collapsible>
            ))}
          </div>
          <div className="Tech__images">
            <div className="background">
              <Arrows />
              <img
                src={IMAGES[openElement]}
                alt="Screenshot z aplikacji Cozato"
                className="screenshot"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const IMAGES = [lista, opis, oferta, filtr];

const TECH = [
  {
    id: 0,
    question: "Przejrzysta lista ogłoszeń",
    answer:
      "Wejdź na Cozato i znajdź przedmioty, których potrzebujesz! Ikonki w lewym górnym rogu ogłoszenia poinformują Cię, czy wystawiający oczekuje czegoś w zamian, czy wystarczy uśmiech. :) Podoba Ci się któryś przedmiot? Kliknij i zobacz szczegóły!"
  },
  {
    id: 1,
    question: "Szczegóły ogłoszenia",
    answer:
      "Tutaj znajdziesz wszystko, co najważniejsze – zdjęcia przedmiotu, jego  krótki opis i komentarz od obecnego właściciela. Zobacz, kiedy, gdzie i za co właściciel proponuje się wymienić. Pasuje? Złóż ofertę!"
  },
  {
    id: 2,
    question: "Składanie oferty wymiany",
    answer:
      "Daj znać, że interesuje Cię wystawiony przedmiot. Wybierz, która lokalizacja i czas najbardziej Ci opowiadają. Daj znać, co oferujesz w zamian lub napisz inną krótką notkę obecnemu właścicielowi. Gotowe? To pogadajmy!"
  },
  {
    id: 3,
    question: "Wyszukiwanie po lokalizacji",
    answer:
      "Szukasz czegoś dla siebie, ale chcesz się wymienić tylko w swojej okolicy lub w drodze do pracy? Nie ma problemu. Zawęź wyniki wyszukiwania do swojej dzielnicy lub wybranej odległości od domu, szkoły czy biura. Po prostu, po sąsiedzku!"
  }
];
