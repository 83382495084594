import "./Blog.scss";

import blog1 from "../assets/blog1.jpg";
import blog2 from "../assets/blog2.jpg";
import blog3 from "../assets/blog3.jpg";

export const Blog = () => (
  <div className="section grey more-whitespace">
    <section className="Blog">
      <h2 className="main-header">
        Blogujemy
        <span className="header-bold"> dla Ciebie</span>
      </h2>

      <p className="subheader">
        Chcesz wiedzieć więcej o życiu less waste, upcyklingu, ponownym używaniu
        i ciekawym wykorzystaniu rzeczy, które już są w obiegu?{" "}
        <span className="text-bold">Odwiedź nasz blog</span> i daj znać w
        komentarzach, jaki jest Twój punkt widzenia.
      </p>

      <div className="Blog__content">
        <div className="Blog__section">
          <img
            src={blog2}
            alt="Kobieta z kartonowym pudłem pełnym domowych przedmiotów, gotowa na wymienianie się."
          />
          <a
            href="https://cozato.pl/blog/5-korzysci-z-wymieniania-sie/"
            target="_blank"
            rel="noopener noreferrer"
            className="small-header header-bold"
          >
            5 korzyści wymian, które zmienią świat na lepszy!
          </a>
        </div>

        <div className="Blog__section">
          <img
            src={blog3}
            alt="Kobieta z torbami rzeczy w maseczce dezynfekuje dłonie czekając na bezpieczne spotkanie wymiankowe"
          />
          <a
            href="https://cozato.pl/blog/bezpieczne-wymienianie-sie-w-trakcie-pandemii/"
            target="_blank"
            rel="noopener noreferrer"
            className="small-header header-bold"
          >
            Bezpieczne wymienianie się w trakcie pandemii
          </a>
        </div>

        <div className="Blog__section">
          <img src={blog1} alt="Pokój pełen perełek modowych second hand" />
          <a
            href="https://cozato.pl/blog/jak-wprowadzic-do-swojej-szafy-ubrania-z-second-handu/"
            target="_blank"
            rel="noopener noreferrer"
            className="small-header header-bold"
          >
            Ubrania z drugiej ręki – zobacz, jak zacząć
          </a>
        </div>
      </div>
    </section>
  </div>
);
