import "./Header.scss";

import { Typewriter } from "react-simple-typewriter";

import headerImage from "../assets/header-image.png";
import { NavBar } from "./NavBar";

const MAGIC_WORDS = [
  "konewkę",
  "kwiatek",
  "jedzenie",
  "kocyk",
  "słoiki",
  "dzbanek",
  "książkę",
  "czasopisma",
  "ubrania",
  "kapelusz",
  "buty",
  "apaszkę",
  "zabawki",
  "maskotkę"
];

export const Header = () => {
  const typeWriterProps = {
    words: MAGIC_WORDS,
    loop: 0,
    typeSpeed: 120,
    deleteSpeed: 80
  };

  return (
    <header className="Header">
      <NavBar />
      <div className="Header__content">
        <h1 className="main-header">
          <span className="header-bold">Podaj dalej</span>{" "}
          <Typewriter {...typeWriterProps} />
        </h1>
        <p className="subheader">
          W Cozato znajdziesz
          <span className="text-bold"> dobrej jakości</span> przedmioty,{" "}
          <span className="text-bold">
            bez wspierania konsumpcjonizmu i z zyskiem dla portfela.
          </span>
        </p>

        <p className="subheader">
          Łatwo pozbędziesz się też
          <span className="text-bold"> niepotrzebnych rzeczy,</span> robiąc
          miejsce w szafie, jednocześnie
          <span className="text-bold"> wspierając naszą planetę.</span>
        </p>

        <button
          type="button"
          className="join-button"
          onClick={() => {
            document.getElementById("join-form").scrollIntoView();
          }}
        >
          Dołącz do Cozato
        </button>

        <img
          className="Header__image"
          src={headerImage}
          alt="Ręcę wymieniające się"
        />
      </div>
    </header>
  );
};
