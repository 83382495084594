export const Form = () => (
  <div className="sib-form">
    <div id="sib-form-container" className="sib-form-container">
      <div id="sib-container">
        <form
          id="sib-form"
          method="POST"
          action="https://b842d253.sibforms.com/serve/MUIEAEDKmBS-Xqs2CnXDA3jGrfem9Ea5-nz80yj3KNtt0fuclNbXx60Fv7VlmLx26IMTdStixCnw6JEE9TCIljWJC2lP04sesrZc5ht1jIw427FEOl0BzvPTzfdt4BKidwdnpj8xkD1H90gUaDFGa0-B3ovXo8V4u0koKLwLmYIx_RfN4S1ahQAEiwFpiTVEEArWGjivQ1rvbD-q"
          data-type="subscription"
          className="JoinForm__form"
        >
          <div className="sib-input sib-form-block">
            <div className="form__entry entry_block">
              <div className="form__label-row JoinForm__element">
                <label
                  className="entry__label"
                  htmlFor="EMAIL"
                  data-required="*"
                >
                  Twój adres e-mail
                </label>
                <div className="entry__field">
                  <input
                    className="input"
                    type="text"
                    id="EMAIL"
                    name="EMAIL"
                    autoComplete="off"
                    data-required="true"
                    required
                  />
                </div>
              </div>
              <label className="entry__error entry__error--primary"></label>
            </div>
          </div>

          <div className="sib-input sib-form-block">
            <div className="form__entry entry_block">
              <div className="form__label-row ">
                <label className="entry__label" htmlFor="CITY">
                  Miasto, w którym mieszkasz
                </label>
                <div className="entry__field">
                  <input
                    className="input"
                    maxLength="200"
                    type="text"
                    id="CITY"
                    name="CITY"
                    autoComplete="off"
                  />
                </div>
              </div>
              <label className="entry__error entry__error--primary"></label>
            </div>
          </div>

          <p className="privacy-policy">
            Wypełniając formularz, akceptujesz naszą{" "}
            <a href="https://www.cozato.pl/polityka-prywatnosci">
              politykę ochrony danych osobowych
            </a>
            .
          </p>

          <button
            className="sib-form-block__button sib-form-block__button-with-loader join-button yellow"
            form="sib-form"
            type="submit"
          >
            Dołącz do Cozato
          </button>

          <div id="error-message" className="sib-form-message-panel">
            <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
              <svg
                viewBox="0 0 512 512"
                className="sib-icon sib-notification__icon"
              >
                <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
              </svg>
              <span className="sib-form-message-panel__inner-text">
                Niestety nie udało się zapisać Twojego adresu. Spróbuj za kilka
                minut.
              </span>
            </div>
          </div>

          <input
            type="text"
            name="email_address_check"
            value=""
            className="input--hidden"
            readOnly
          />
          <input type="hidden" name="locale" value="en" readOnly />
        </form>
      </div>
    </div>
  </div>
);
