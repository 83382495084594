import "./NavBar.scss";

import { useState } from "react";
import { slide as Menu } from "react-burger-menu";

import { ReactComponent as Logo } from "../assets/logo.svg";

export const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = state => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="NavBar">
      <Logo />

      <div className="nav-desktop">
        <a id="home" className="menu-item" href="#how-it-works">
          Jak to działa?
        </a>
        <a id="about" className="menu-item" href="#benefits">
          Twoje korzyści
        </a>
        <a id="about" className="menu-item" href="#tech">
          Zobacz więcej
        </a>
        <a id="contact" className="menu-item" href="#about-us">
          O nas
        </a>
        <a
          id="contact"
          className="menu-item"
          href="/blog"
          target="_blank"
          rel="noopener noreferrer"
        >
          Blog
        </a>
        <a id="contact" className="menu-item" href="#faq">
          FAQ
        </a>
      </div>

      <div className="nav-mobile">
        <Menu
          right
          isOpen={menuOpen}
          onStateChange={state => handleStateChange(state)}
        >
          <a
            id="home"
            className="menu-item"
            href="#how-it-works"
            onClick={() => closeMenu()}
          >
            Jak to działa?
          </a>
          <a
            id="about"
            className="menu-item"
            href="#benefits"
            onClick={() => closeMenu()}
          >
            Twoje korzyści
          </a>
          <a
            id="about"
            className="menu-item"
            href="#tech"
            onClick={() => closeMenu()}
          >
            Zobacz więcej
          </a>
          <a
            id="contact"
            className="menu-item"
            href="#about-us"
            onClick={() => closeMenu()}
          >
            O nas
          </a>
          <a
            id="contact"
            className="menu-item"
            href="/blog"
            onClick={() => closeMenu()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
          <a
            id="contact"
            className="menu-item"
            href="#faq"
            onClick={() => closeMenu()}
          >
            FAQ
          </a>
        </Menu>
      </div>
    </nav>
  );
};
